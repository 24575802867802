import { useEffect, useState } from 'react';
import './QueHacemos.css';

function QueHacemos() {

  const [selectedTarjeta, setSelectedTarjeta] = useState(0)

  const [modalVisible, setModalVisible] = useState(false)

  const tarjetas = [{
    title: "DISEÑO",
    text: "Info de próximos partidos, resultados, convocatorias o torneos. Cuándo le ponés diseño a tus fotos, cambia todo. Profesionalizá tu imagen.",
    img: "/imgs/que-hacemos/diseño.svg"
  },
  {
    title: "REDES SOCIALES",
    text: "Instagram es unos de los canales principales para mostrar tus logros. Dale contenido creativo a tus seguidores y llamá la atención de nuevos sponsors.",
    img: "/imgs/que-hacemos/redes.svg"
  },
  {
    title: "MARCAS",
    text: "Creamos tu marca propia, la difundimos y generamos ingresos adicionales. El futuro de tu familia, también es nuestra prioridad. Invertí en tu presente.",
    img: "/imgs/que-hacemos/marcas.svg"
  },
  {
    title: "EVENTOS DEPORTIVOS",
    text: "Si querés realizar una acción benéfica o institucional, contanos tu idea y organizamos todo. No te preocupes por nada.",
    img: "/imgs/que-hacemos/eventos.svg"
  },
  {
    title: "PRODUCCIÓN AUDIOVISUAL",
    text: "Realizamos coberturas de todo tipo. Te acompañamos con nuestros equipos y registramos tu evento con una perspectiva diferente. Somos creativos digitales.",
    img: "/imgs/que-hacemos/audiovisual.svg"
  }]

  const handleCardOpen = index => {
    setSelectedTarjeta(index)
    setModalVisible(true)
  }

  useEffect(() => {
    if (modalVisible) {
      const scrollY = window.scrollY
      document.body.style.position = 'fixed'
      document.body.style.top = '-' + scrollY + 'px'
    } else {
      const scrollY = document.body.style.top
      document.body.style.position = ''
      document.body.style.top = ''
      window.scrollTo(0, parseInt(scrollY || '0') * -1)
    }
  }, [modalVisible])

  return (
    <div className="QueHacemos">
      <h3>¿QUÉ HACEMOS?</h3>
      <div className="QueHacemos-tarjetas">
        {tarjetas.map((tarjeta, index) =>
          <button className="QueHacemos-tarjeta" key={index} onClick={() => handleCardOpen(index)}>
            <img src={process.env.PUBLIC_URL + tarjeta.img} alt="" />
            <h5>{tarjeta.title}</h5>
          </button>)}
      </div>

      <div className="QueHacemos-modal" onClick={() => setModalVisible(false)} style={{ opacity: modalVisible ? '1' : '0', visibility: modalVisible ? 'visible' : 'hidden', transition: modalVisible ? 'visibility 0s, opacity .5s ease-in-out' : 'visibility 0s .5s, opacity .5s ease-in-out' }}>
        <div className="QueHacemos-modal-container" onClick={e => e.stopPropagation()}>
          <img src={process.env.PUBLIC_URL + tarjetas[selectedTarjeta].img} alt="" />
          <h5>{tarjetas[selectedTarjeta].title}</h5>
          <p>{tarjetas[selectedTarjeta].text}</p>

          <button style={{ backgroundImage: 'url(' + process.env.PUBLIC_URL + '/imgs/que-hacemos/cerrar.svg)' }} onClick={() => setModalVisible(false)}></button>
        </div>
      </div>

    </div>
  );
}

export default QueHacemos;