import './Portada.css';
import { Link as ScrollLink } from 'react-scroll';
import { useEffect, useState } from 'react';

function Portada() {

  const [mobile, setMobile] = useState(window.innerWidth <= 900)

  useEffect(() => {
    window.addEventListener('resize', () => setMobile(window.innerWidth <= 900))
    return () => window.removeEventListener('resize', () => setMobile(window.innerWidth <= 900))
  }, [])

  const elements = [
    {
      background: '/imgs/portada/1.png',
      backgroundMobile: '/imgs/portada/1-mobile.png',
      content: <div className="Portada-element-1">
        <img src={process.env.PUBLIC_URL + '/imgs/logo.svg'} alt="DeporCrea" />
        <h2>Compartimos tu pasión</h2>
        <ScrollLink className="Portada-element-1-link" to="Contacto" smooth onClick={() => window.gtag('event', 'Clic', {'event_category': 'Boton', 'event_label': 'Sumate'})}>SUMATE</ScrollLink>
      </div>
    },
    {
      background: '/imgs/portada/2.png',
      backgroundMobile: '/imgs/portada/2-mobile.png',
      content: <h2 className="Portada-element-2">INSPIRADOS EN<br /><b>ÁNGEL CLAUDINO</b>
      </h2>
    }
  ]

  const [selectedElement, setSelectedElement] = useState(0)

  const handleBackArrow = () => {
    setSelectedElement(prevSelectedElement => prevSelectedElement === 0 ? elements.length - 1 : prevSelectedElement - 1)
  }

  const handleForwadArrow = () => {
    setSelectedElement(prevSelectedElement => prevSelectedElement === elements.length - 1 ? 0 : prevSelectedElement + 1)
  }

  const [myTimeout, setMyTimeout] = useState()

  useEffect(() => {
      clearTimeout(myTimeout)
      setMyTimeout(setTimeout(handleForwadArrow, 10000))
  }, [selectedElement])

  return (
    <div className="Portada">
      <button className="Portada-arrow" style={{ top: 'calc(50% - 21px)', left: (mobile ? '10px' : '25px'), boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.4)' }} onClick={handleBackArrow}><img src={process.env.PUBLIC_URL + '/imgs/portada/flecha.svg'} alt="Anterior" /></button>
      <button className="Portada-arrow" style={{ top: 'calc(50% - 15px)', right: (mobile ? '10px' : '25px'), boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.4)', transform: 'rotate(180deg)' }} onClick={handleForwadArrow}><img src={process.env.PUBLIC_URL + '/imgs/portada/flecha.svg'} alt="Siguiente" /></button>

      <div className="Portada-content" style={{ marginLeft: 'calc(-100% * ' + selectedElement + ')', width: 100 * elements.length + '%' }}>
        {elements.map((element, index) =>
          <div className="Portada-element" style={{ backgroundImage: 'url(' + process.env.PUBLIC_URL + (!mobile ? element.background : element.backgroundMobile) + ')' }} key={index}>
            {element.content}
          </div>
        )}
      </div>

      <div className="Portada-circles">
        <button className={selectedElement === 0 ? "Portada-circle-disabled" : "Portada-circle"} onClick={() => { if (selectedElement !== 0) setSelectedElement(0) }}></button>
        <button className={selectedElement === 1 ? "Portada-circle-disabled" : "Portada-circle"} onClick={() => { if (selectedElement !== 1) setSelectedElement(1) }}></button>
      </div>
    </div>
  );
}

export default Portada;