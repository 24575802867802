import './Somos.css';

function Somos() {
  return (
    <div className="Somos">
      <h3>SOMOS</h3>
      <p><b>Somos un equipo que sale a la cancha mientras vos jugás.</b><br />Somos un equipo, sí, como el tuyo.<br />Sólo que lo nuestro es la <b>comunicación audiovisual</b>. Con esta herramienta vamos poder registrar <b>tus logros, tu esfuerzo, tus emociones</b>, para que puedas lucirte en redes sociales y <b>sentirte más cerca de tus seguidores y sponsors.</b></p>
    </div>
  );
}

export default Somos;