import './Navbar.css';
import { Link as ScrollLink } from 'react-scroll';
import { useEffect, useState } from 'react';

function Navbar() {

    const [mobile, setMobile] = useState(window.innerWidth <= 900)

    useEffect(() => {
        window.addEventListener('resize', () => setMobile(window.innerWidth <= 900))
        return () => window.removeEventListener('resize', () => setMobile(window.innerWidth <= 900))
    }, [])

    const [menuOpen, setMenuOpen] = useState(false)

    return (
        <div className="Navbar" style={{ height: !mobile ? '96px' : !menuOpen ? '66px' : '200px' }}>
            <div className="Navbar-content">
                <ScrollLink smooth to="Portada" className="Navbar-logo"><img src={process.env.PUBLIC_URL + '/imgs/logo.svg'} alt="DeporCrea" /></ScrollLink>
                {mobile ?
                    <button className="Navbar-burguer" onClick={() => setMenuOpen(prevMenuOpen => !prevMenuOpen)}>
                        <div className="Navbar-burguer-topBun" style={menuOpen ? { transform: 'rotate(45deg) translate(7px, 7px)' } : null}></div>
                        <div className="Navbar-burguer-burguer" style={menuOpen ? { opacity: '0' } : null}></div>
                        <div className="Navbar-burguer-bottomBun" style={menuOpen ? { transform: 'rotate(-45deg) translate(6px, -6px)' } : null}></div>
                    </button> : null}
                <div className="Navbar-buttons">
                    <ScrollLink smooth to="Somos" className="Navbar-button" onClick={() => setMenuOpen(false)}>SOMOS</ScrollLink>
                    <ScrollLink smooth to="QueHacemos" className="Navbar-button" onClick={() => setMenuOpen(false)}>¿QUÉ HACEMOS?</ScrollLink>
                    <ScrollLink smooth to="Contacto" className="Navbar-button" onClick={() => setMenuOpen(false)}>CONTACTO</ScrollLink>
                </div>
            </div>
        </div>
    );
}

export default Navbar;