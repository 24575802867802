import './Contacto.css';
import Formulario from './inner/Formulario'
import { useEffect, useState } from 'react';

function Contacto() {

  const [mobile, setMobile] = useState(window.innerWidth <= 900)

  useEffect(() => {
    window.addEventListener('resize', () => setMobile(window.innerWidth <= 900))
    return () => window.removeEventListener('resize', () => setMobile(window.innerWidth <= 900))
  }, [])

  return (
    <div className="Contacto" style={{ backgroundImage: 'url(' + process.env.PUBLIC_URL + (!mobile ? '/imgs/contacto/fondo.png)' : '/imgs/contacto/fondo-mobile.png)') }}>
      <div className="Contacto-fadeTop"></div>

      <div className="Contacto-content">
        <div className="Contacto-left">
          <img className="Contacto-logo" src={process.env.PUBLIC_URL + '/imgs/contacto/logo-blanco.svg'} alt="DeporCrea" />
          <div className="Contacto-social">
            <a className="Contacto-social-button" href="https://instagram.com/deporcrea" target="_blank" rel="noreferrer" onClick={() => window.gtag('event', 'Clic', { 'event_category': 'Boton', 'event_label': 'Instagram' })}><img src={process.env.PUBLIC_URL + '/imgs/contacto/ig.svg'} alt="Instagram" /></a>
            <a className="Contacto-social-button" href="https://api.whatsapp.com/send?phone=5491123017691&text=%C2%A1Hola!%20Quiero%20m%C3%A1s%20informaci%C3%B3n" target="_blank" rel="noreferrer" onClick={() => window.gtag('event', 'Clic', { 'event_category': 'Boton', 'event_label': 'WhatsApp' })}><img src={process.env.PUBLIC_URL + '/imgs/contacto/wa.svg'} alt="WhatsApp" /></a>
            <a className="Contacto-social-button" href="https://www.youtube.com/channel/UCKWqAi0MHX56SS9pCJHj0Tw" target="_blank" rel="noreferrer" onClick={() => window.gtag('event', 'Clic', { 'event_category': 'Boton', 'event_label': 'Youtube' })}><img src={process.env.PUBLIC_URL + '/imgs/contacto/yt.svg'} alt="YouTube" /></a>
          </div>
        </div>

        <div className="Contacto-right">
          <Formulario />
        </div>
      </div>

      <div className="Contacto-fadeBottom"></div>
    </div>
  );
}

export default Contacto;