import './App.css';
import Navbar from './components/navbar/Navbar'
import Portada from './components/portada/Portada'
import Somos from './components/somos/Somos'
import QueHacemos from './components/que-hacemos/QueHacemos'
import Contacto from './components/contacto/Contacto'
import { useEffect, useState } from 'react';

function App() {

  const [mobile, setMobile] = useState(window.innerWidth <= 900)

  useEffect(() => {
    window.addEventListener('resize', () => setMobile(window.innerWidth <= 900))
    return () => window.removeEventListener('resize', () => setMobile(window.innerWidth <= 900))
  }, [])

  return (
    <div className="App">
      <Navbar />
      <Portada />
      <div className="App-SomosHacemos" style={{ backgroundImage: 'url(' + process.env.PUBLIC_URL + (!mobile ? '/imgs/fondo-somoshacemos.png)' : '/imgs/fondo-somoshacemos-mobile.png)') }}>
        <div className="App-SomosHacemos-fadeTop"></div>
        <Somos />
        <QueHacemos />
        <div className="App-SomosHacemos-fadeBottom"></div>
      </div>
      <video className="App-YTVideo" controls poster={process.env.PUBLIC_URL + '/imgs/deporcrea-poster.png'}>
        <source src={process.env.PUBLIC_URL + "/deporcrea.mp4"} type="video/mp4" />
        Tu navegador no soporta la visualización de videos.
      </video>
      <Contacto />
    </div>
  );
}

export default App;