import { useState } from 'react';
import './Formulario.css';

function Formulario() {

  const [showModal, setShowModal] = useState(false)

  const [nombre, setNombre] = useState('')
  const [email, setEmail] = useState('')
  const [telefono, setTelefono] = useState('')
  const [mensaje, setMensaje] = useState('')

  const submitForm = e => {
    e.preventDefault()
    if (nombre !== '' && email !== '' && telefono !== '' && mensaje !== '') {
      setShowModal(true)
      const scrollY = window.scrollY
      document.body.style.position = 'fixed'
      document.body.style.top = '-' + scrollY + 'px'

      const deporcreaForm = {};

      deporcreaForm.nombre = nombre;
      deporcreaForm.email = email;
      deporcreaForm.telefono = telefono;
      deporcreaForm.mensaje = mensaje;

      const jsonData = JSON.stringify(deporcreaForm);

      let xhr = new XMLHttpRequest();
      xhr.open('POST', 'https://newsletterservice.com.ar/landing/api/deporcrea');

      xhr.onreadystatechange = function () {
        if (xhr.readyState === 4) {
          if (xhr.status === 200) {
            window.gtag('event', 'Clic', { 'event_category': 'Formulario', 'event_label': 'Enviar' })
            alert("Formulario enviado con éxito. Lo contactaremos a la brevedad.")
          } else {
            alert("Error al enviar el formulario, por favor vuelva a intentarlo o comuníquese por otra vía.")
          }
          setShowModal(false)
          const scrollY = document.body.style.top;
          document.body.style.position = '';
          document.body.style.top = '';
          window.scrollTo(0, parseInt(scrollY || '0') * -1);
        }
      };
      xhr.setRequestHeader('Content-Type', 'application/json');
      xhr.send(jsonData);

    } else {
      alert('Por favor, complete todos los campos antes de enviar el formulario.')
    }
  }

  return (
    <form className="Formulario" onSubmit={submitForm}>
      <h3>CONTACTANOS</h3>
      <input className="Formulario-input" type="text" onChange={e => setNombre(e.target.value)} value={nombre} placeholder="Nombre"></input>
      <input className="Formulario-input" type="email" onChange={e => setEmail(e.target.value)} value={email} placeholder="Email"></input>
      <input className="Formulario-input" type="tel" onChange={e => setTelefono(e.target.value)} value={telefono} placeholder="Teléfono"></input>
      <textarea className="Formulario-mensaje" onChange={e => setMensaje(e.target.value)} value={mensaje} placeholder="Mensaje"></textarea>
      <input className="Formulario-submit" type="submit" value="ENVIAR"></input>

      {showModal ?
        <div className="Formulario-modal">
          <img src={process.env.PUBLIC_URL + '/imgs/contacto/cargando.gif'} alt="" />
          <p>Enviando el formulario, por favor aguarda</p>
        </div>
        : null}
    </form>
  );
}

export default Formulario;